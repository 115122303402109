:root{
    --nav-name-color: #E41E1E;
    --nav-lets-connect-color: #E76969;
    --bg-color:#ffffff;
    --text-primary:#1C3FCA;
    --bg-primary:#1C3FCA;
    --bg-blacks:#092133;
    /* --bg-test:#505254; */
    /* --bg-test:#092133; */

}